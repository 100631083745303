export const environment = {
  baseEndpoint: "https://api-qa.talkingstick.app/",
  buildNumber: "28893743",  
  versionNumber: "3.0.6",  
  environment_deploy: "qa",
  rayGunKey: "hqaZDpkENyp0WOVqpDhdg",
  authResultKey: "1f8eb139-b450-45b7-aed8-5f7579dd0441",
  cryptoKey: "9af6f8c9-c511-4af7-a470-2a9833cd4de2",
  posthogToken: "phc_7hJXVERK7T2Owd3OeGSDGUMeyTHBLKAjnBwQD3ZuwWJ",
  orgCode: "burns",
  locizeProjectId: "714c2f9a-6c84-4052-b7f0-1e6469e617d7",

  firebaseConfig: {
    apiKey: "AIzaSyDw0jsp6KgNxFmL5jsTuxtNitmbalS37uo",
    authDomain: "the-burns-way-qa.firebaseapp.com",
    projectId: "the-burns-way-qa",
    storageBucket: "the-burns-way-qa.appspot.com",
    messagingSenderId: "251811468787",
    appId: "1:251811468787:web:21c4e667492e8061e12e86",
    vapidKey:
      "BBBb5Hu9_RicoCLbyRxdU6nnoV5_u46EInkgrbeB5-0jsDQACJhe7wJzH1xEfoNl-FjsJteJZkSYMM_8fcUFlCM",
  },
};
